var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Depósito")]),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{staticClass:"text-right",attrs:{"indeterminate":"","color":"primary","size":"24"}}):_vm._e(),(_vm.screen == 'messages')?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"message-with-header"},[_vm._v("Mensagem De: "+_vm._s(_vm.contact)+" | "+_vm._s(_vm.instance_name))]),_c('v-expansion-panel-content',{staticClass:"message-with-content"},[_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","hide-details":"true","rows":"5","value":_vm.message_deposit}}),(_vm.fileBase64)?_c('v-card',[_c('v-img',{attrs:{"src":_vm.fileBase64,"contain":""}})],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{attrs:{"label":"Status *","items":_vm.possible_status,"readonly":true,"error-messages":_vm.errors.collect('status'),"rules":[_vm.rules.requiredAcceptZero],"item-text":"description","item-value":"id","data-vv-name":"status","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{attrs:{"label":"Tipo de Pagamento *","items":this.mode == 'VIEW'
                                            ? _vm.payment_types_info
                                            : _vm.payment_types,"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH' ||
                                        this.type == 4,"rules":[_vm.rules.requiredAcceptZero],"item-text":"description","item-value":"id","outlined":""},on:{"change":function($event){_vm.type = null}},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),_c('v-flex',[(_vm.payment_type == 1)?_c('v-select',{attrs:{"label":"Tipo de Anotado","items":_vm.types_annotated,"item-text":"description","item-value":"id"},model:{value:(_vm.type_annotated),callback:function ($$v) {_vm.type_annotated=$$v},expression:"type_annotated"}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm.e2e_identified)+" "),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(_vm.e2e_identified)?_c('v-text-field',{attrs:{"readonly":"","label":"Identificador Extraído do Comprovante","error-messages":_vm.errors.collect('e2e_identified'),"data-vv-name":"e2e_identified","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","title":_vm.copied
                                                    ? 'Copiado!'
                                                    : 'Copiar'},on:{"click":function($event){return _vm.copyToClipboard(
                                                    _vm.e2e_identified,
                                                )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.copied ? 'mdi-check' : 'mdi-content-copy'))])],1)]},proxy:true}],null,false,2902722982),model:{value:(_vm.e2e_identified),callback:function ($$v) {_vm.e2e_identified=$$v},expression:"e2e_identified"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(
                                        (!_vm.payment_type &&
                                            _vm.payment_type != 0) ||
                                        _vm.payment_type == 3
                                    )?_c('v-text-field',{attrs:{"required":_vm.payment_type == 3,"label":"Identificador","disabled":_vm.loadingE2E,"error-messages":_vm.errors.collect('endToEndId'),"rules":[
                                        (value) =>
                                            _vm.payment_type !== 3 ||
                                            !!value ||
                                            'Identificador é obrigatório',
                                        (value) =>
                                            value.length <= 36 ||
                                            'Máximo de 36 caracteres',
                                    ],"data-vv-name":"endToEndId","outlined":""},on:{"input":_vm.handleChangeEndToEndId},model:{value:(_vm.endToEndId),callback:function ($$v) {_vm.endToEndId=$$v},expression:"endToEndId"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"disabled":_vm.payment_type == null ||
                                        _vm.payment_type == 3,"label":"Valor *","readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH',"error-messages":_vm.errors.collect('value'),"data-vv-name":"value","outlined":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{attrs:{"label":"Tipo de Envio *","disabled":!this.payment_type &&
                                        this.payment_type != 0,"items":_vm.typeByPaymentType,"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH',"rules":[_vm.rules.requiredAcceptZero],"item-text":"description","item-value":"id","outlined":""},on:{"change":function($event){return _vm.typeSelectOnChange()}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(
                                    _vm.payment_type == 0 || _vm.payment_type == 3
                                )?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{attrs:{"clearable":true,"clear-icon":"mdi-close","label":"Banco *","items":_vm.bankDeposits,"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH' ||
                                        this.type == 4,"disabled":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH' ||
                                        this.type == 4,"error-messages":_vm.errors.collect('bank'),"rules":[_vm.rules.requiredAcceptZero],"item-text":_vm.getTextBank,"item-value":"id","data-vv-name":"bank","outlined":""},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1):_vm._e(),(_vm.showPlayerFields())?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":_vm.getPlayerLabel('ID Suprema'),"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH',"rules":[
                                        _vm.rules.required,
                                        _vm.rules.max_length(_vm.id_pppoker, 10),
                                        _vm.rules.onlyNumbers,
                                    ],"outlined":""},model:{value:(_vm.id_pppoker),callback:function ($$v) {_vm.id_pppoker=$$v},expression:"id_pppoker"}})],1):_vm._e(),(_vm.showPlayerFields())?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":_vm.getPlayerLabel('Plataforma'),"items":_vm.plataforms,"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH',"item-text":"description","item-value":"id","rules":[_vm.rules.requiredAcceptZero],"outlined":""},on:{"change":function($event){_vm.id_instance = null}},model:{value:(_vm.plataform),callback:function ($$v) {_vm.plataform=$$v},expression:"plataform"}})],1):_vm._e(),(_vm.showPlayerFields())?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":_vm.getPlayerLabel('Clube'),"items":_vm.clubsByPlatform,"disabled":!_vm.plataform && _vm.plataform != 0,"readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH',"item-text":_vm.getTextSlot,"item-value":"id","rules":[_vm.rules.requiredAcceptZero],"outlined":""},model:{value:(_vm.id_instance),callback:function ($$v) {_vm.id_instance=$$v},expression:"id_instance"}})],1):_vm._e(),(_vm.type == 2)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"CPF/CNPJ *","readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH' ||
                                        _vm.payment_type == 3,"rules":[
                                        _vm.rules.max_length(
                                            _vm.pix_identifier,
                                            30,
                                        ),
                                        _vm.rules.cpf_cnpj,
                                        _vm.rules.required,
                                    ],"outlined":""},model:{value:(_vm.pix_identifier),callback:function ($$v) {_vm.pix_identifier=$$v},expression:"pix_identifier"}})],1):_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"CPF/CNPJ","readonly":this.mode == 'VIEW' ||
                                        this.mode == 'FINISH' ||
                                        _vm.payment_type == 3,"rules":[
                                        _vm.rules.max_length(
                                            _vm.pix_identifier,
                                            30,
                                        ),
                                    ],"outlined":""},model:{value:(_vm.pix_identifier),callback:function ($$v) {_vm.pix_identifier=$$v},expression:"pix_identifier"}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","md8":""}},[_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","label":"Observação","id":"textareamessage","hide-details":"true","rows":"1","rules":[
                                        _vm.rules.max_length(_vm.observation, 254),
                                    ],"value":_vm.observation},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":"","md2":""}},[_c('v-checkbox',{attrs:{"label":"Dar Bônus","rules":[
                                        _vm.rules.bonus(_vm.bonus, _vm.pix_identifier),
                                    ]},model:{value:(_vm.bonus),callback:function ($$v) {_vm.bonus=$$v},expression:"bonus"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":"","md2":""}},[_c('v-checkbox',{attrs:{"label":"Free Roll"},model:{value:(_vm.free_roll),callback:function ($$v) {_vm.free_roll=$$v},expression:"free_roll"}})],1),(this.mode == 'VIEW')?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Auditoria")]),_c('v-expansion-panel-content',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"Criado em","readonly":true,"outlined":"","value":_vm.getDate(
                                                            this
                                                                .date_created,
                                                        )}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"Finalizado em","readonly":true,"outlined":"","value":_vm.getDate(
                                                            this.finish_at,
                                                        )}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"Criado Por","readonly":true,"outlined":"","value":this
                                                            .created_user_name}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"Finalizado Por","readonly":true,"outlined":"","value":this
                                                            .finish_user_name}})],1)],1)],1)],1)],1):_vm._e(),(this.mode == 'VIEW' && this.pix_id)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Pix")]),_c('v-expansion-panel-content',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"CPF/CNPJ","readonly":true,"outlined":"","value":_vm.pix.client_key}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Data","readonly":true,"outlined":"","value":_vm.getDate(_vm.pix.date)}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Valor","readonly":true,"outlined":"","value":_vm.pix.value.toLocaleString(
                                                            'pt-BR',
                                                            {
                                                                style: 'currency',
                                                                currency:
                                                                    'BRL',
                                                            },
                                                        )}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"label":"Identificador","readonly":true,"outlined":"","value":_vm.pix.endToEndId
                                                            ? _vm.pix.endToEndId
                                                            : _vm.pix.txId}})],1)],1)],1)],1)],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(this.mode == 'ADD')?_c('v-file-input',{staticClass:"mt-2",attrs:{"label":"Comprovante","outlined":""},model:{value:(_vm.receipt_file),callback:function ($$v) {_vm.receipt_file=$$v},expression:"receipt_file"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-col',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.mode == 'VIEW' ? 'Voltar' : 'Cancelar'))])],1),(_vm.showButtonConfirmation || _vm.showButtonIgnoreExists)?_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveDeposit}},[_vm._v("Confirmar")])],1):_vm._e(),(_vm.mode != 'VIEW')?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","type":"submit","right":"","disabled":_vm.block_click},on:{"click":_vm.saveDeposit}},[_vm._v("Salvar")])],1):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }